import Constants from "expo-constants";

const {manifest} = Constants;

export type RootState = any;

let api: string | undefined = "";
if (typeof manifest?.packagerOpts === `object` && manifest.packagerOpts.dev) {
  api = `http://${manifest.debuggerHost?.split(`:`).shift()}:3000`;
  if (api.includes("exp.direct")) {
    api = "https://dev.nang.io";
  }
}

export const baseUrl: string =
  api || Constants.manifest?.extra?.BASE_URL || "http://localhost:3000";
console.info(`Base URL set to ${baseUrl}`);
