import axios from "axios";
import qs from "querystring";
import * as React from "react";
import {Image, Linking, View} from "react-native";
import {connect, ConnectedProps} from "react-redux";
import {Box, Button, Heading, Page, Pill, Switch, Text, TextField, WithLabel} from "react-unifier";

import {baseUrl} from "../mongoose-redux/constants";
import {HomeStackScreenProps} from "../types";

// TODO copy in
type Album = any;
type Img = any;

interface TrieNode {
  isLeaf: boolean;
  children: {[key: string]: TrieNode};
}

class Trie {
  private root: TrieNode = {
    isLeaf: false,
    children: {},
  };

  newNode(): TrieNode {
    return {
      isLeaf: false,
      children: {},
    };
  }

  add(word: string) {
    if (!word) {
      return;
    }

    let root = this.root;
    for (const letter of word.toLowerCase()) {
      if (!(letter in root.children)) {
        root.children[letter] = this.newNode();
      }
      root = root.children[letter];
    }
    root.isLeaf = true;
  }

  // Pick a starting node
  findStartNode(word: string): TrieNode | null {
    if (!word) {
      return this.root;
    }
    let root = this.root;
    for (const letter of word) {
      if (letter in root.children) {
        root = root.children[letter];
      } else {
        return null;
      }
    }

    return root;
  }

  traverse(root: TrieNode, word: string): string[] {
    if (root.isLeaf) {
      return [word];
    }

    let results: string[] = [];
    for (const letter in root.children) {
      results = results.concat(this.traverse(root.children[letter], word + letter));
    }
    return results;
  }

  complete(subtext: string | null, CHILDREN = null): string[] {
    if (!subtext) {
      return [];
    }
    const root = this.findStartNode(subtext.toLowerCase());

    if (!root) {
      return [];
    }

    let results: string[] = [];

    const children = root.children;

    const spread = 0;

    for (const letter in children) {
      results = results.concat(this.traverse(children[letter], subtext + letter));
    }

    return results;
  }
}

interface HomeScreenProps extends PropsFromRedux, HomeStackScreenProps<"Home"> {}

interface HomeScreenState {
  artistName: string;
  albumName: string;
  imageLabels: string;
  selectedLabels: string[];
  imageText: string;
  labelOptions: string[];
  results: Album[];
  autocompleteOptions: string[];
  showAll: boolean;
  showText: boolean;
}

const trie = new Trie();

class HomeScreen extends React.Component<HomeScreenProps, HomeScreenState> {
  constructor(props: HomeScreenProps) {
    super(props);
    this.state = {
      artistName: "",
      albumName: "",
      imageLabels: "",
      imageText: "",
      labelOptions: [],
      selectedLabels: [],
      results: [],
      autocompleteOptions: [],
      showAll: false,
      showText: false,
    };
  }

  componentDidMount() {
    this.fetchOptions();
  }

  fetchOptions = async () => {
    const result = await axios.get(`${baseUrl}/options`);
    console.debug("Label options", result.data?.options);
    this.setState({labelOptions: result.data?.options});

    for (const opt of result.data?.options ?? []) {
      trie.add(opt);
    }
  };

  search = async () => {
    this.setState({results: []});
    const query: any = {};
    for (const key of ["artistName", "albumName", "imageText"] as (keyof HomeScreenState)[]) {
      if (this.state[key]) {
        query[key] = key === "imageLabels" ? [this.state[key]] : this.state[key];
      }
    }
    if (this.state.selectedLabels.length > 0) {
      query.imageLabels = this.state.selectedLabels;
    }
    console.debug("Search query", query);
    const result = await axios.get(`${baseUrl}/search?${qs.stringify(query)}`);
    console.debug("Search results", result.data.results);
    this.setState({results: result.data.results});
  };

  setImageLabelText = (text: string) => {
    // Filter all the options to see what we should put in the auto complete.
    this.setState({imageLabels: text, autocompleteOptions: trie.complete(text)});
  };

  selectImageLabel = (text: string) => {
    if (this.state.selectedLabels.includes(text)) {
      return;
    }
    if (!this.state.labelOptions.find((o) => o?.toLowerCase() === text?.toLowerCase())) {
      return;
    }
    const label = this.state.labelOptions.find((o) => o?.toLowerCase() === text.toLowerCase());
    if (!label) {
      return;
    }
    this.setState({
      selectedLabels: [...(this.state.selectedLabels ?? []), label],
      autocompleteOptions: [],
      imageLabels: "",
    });
  };

  renderImage = (image: Img) => {
    if (!this.state.showAll) {
      if (!image.front && !image.back) {
        return null;
      }
    }
    return (
      <Box key={image.index} width="100%" onClick={() => Linking.openURL(image.url)}>
        <Box paddingY={1} width="100%">
          <Image
            source={{uri: image.url.replace(".jpg", "-500.jpg").replace(".png", "-500.png")}}
            style={{maxWidth: 240, maxHeight: 240, width: 240, minHeight: 240, marginRight: 16}}
          />
        </Box>
        <Box width="100%">
          {this.state.showText && image.labelOriginalText && (
            <Text>Text: {image.labelOriginalText}</Text>
          )}
          {this.state.showText && image.comment && <Text>Comment: {image.comment}</Text>}
          {this.state.showText && image.types?.length > 0 && (
            <Text>Types: {image.types.join(", ")}</Text>
          )}
        </Box>
      </Box>
    );
  };

  render() {
    return (
      <Page maxWidth={900} navigation={this.props.navigation} scroll>
        <Box
          alignItems="center"
          alignSelf="center"
          height="100%"
          justifyContent="center"
          maxWidth={900}
          padding={8}
          width="100%"
        >
          <Box paddingY={2} width="100%">
            <TextField
              label="Artist Name"
              value={this.state.artistName}
              onChange={(result) => this.setState({artistName: result.value})}
            />
          </Box>
          <Box paddingY={2} width="100%">
            <TextField
              label="Album Name"
              value={this.state.albumName}
              onChange={(result) => this.setState({albumName: result.value})}
            />
          </Box>
          <Box paddingY={2} position="relative" width="100%">
            {this.state.selectedLabels.length > 0 && (
              <Box direction="row" paddingY={2} width="100%">
                {this.state.selectedLabels.map((label) => (
                  <Box key={label} marginRight={1}>
                    <Pill
                      color="blue"
                      text={label}
                      onClick={() => {
                        this.setState({
                          selectedLabels: this.state.selectedLabels.filter((l) => l !== label),
                        });
                      }}
                    />
                  </Box>
                ))}
              </Box>
            )}
            <WithLabel
              label="Objects detected on an album cover by Google Vision"
              labelPlacement="after"
              labelSize="sm"
              show
            >
              <TextField
                label="Image Labels"
                value={this.state.imageLabels}
                onChange={(result) => {
                  this.setImageLabelText(result.value);
                }}
                onSubmitEditing={() => {
                  this.selectImageLabel(this.state.imageLabels);
                }}
              />
            </WithLabel>
            {this.state.autocompleteOptions.length > 0 && (
              <View
                style={{
                  backgroundColor: "white",
                  padding: 2 * 4,
                  marginTop: 20,
                  width: "100%",
                  maxHeight: 300,
                  overflow: "scroll",
                  zIndex: 999,
                }}
              >
                {this.state.autocompleteOptions.map((opt: string, idx: number) => (
                  <Box key={idx} paddingY={1} onClick={() => this.selectImageLabel(opt)}>
                    <Text>{opt}</Text>
                  </Box>
                ))}
              </View>
            )}
          </Box>
          <Box paddingY={2} width="100%">
            <WithLabel
              label="Text that is written on an album cover"
              labelPlacement="after"
              labelSize="sm"
              show
            >
              <TextField
                label="Image Text"
                value={this.state.imageText}
                onChange={(result) => this.setState({imageText: result.value})}
              />
            </WithLabel>
          </Box>
          <Box alignSelf="start" paddingY={2} width="50%">
            <WithLabel label="Show all images?">
              <Switch
                switched={this.state.showAll}
                onChange={(value) => this.setState({showAll: value})}
              />
            </WithLabel>
          </Box>
          <Box alignSelf="start" paddingY={2} width="50%">
            <WithLabel label="Show text identified on images?">
              <Switch
                switched={this.state.showText}
                onChange={(value) => this.setState({showText: value})}
              />
            </WithLabel>
          </Box>
          <Box alignSelf="start" paddingY={2} width="50%">
            <Button color="blue" text="Search" onClick={this.search} />
          </Box>

          <Box marginTop={12}>
            <Box>
              <Text size="lg" weight="bold">
                {this.state.results.length} results
              </Text>
            </Box>
            {this.state.results.map((r) => (
              <Box key={r.id} paddingY={2} width="100%">
                <Heading>
                  &quot;{r.name}&quot; by {r.artist.name}
                </Heading>
                <Box direction="row" width="100%" wrap>
                  {r.images.length === 0 && (
                    <Box paddingY={3}>
                      <Text size="lg" weight="bold">
                        No Images
                      </Text>
                    </Box>
                  )}
                  {r.images.map(this.renderImage)}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Page>
    );
  }
}

const connector = connect(
  (state: any, props) => ({
    ...props,
  }),
  {}
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(HomeScreen);
