/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {FontAwesome} from "@expo/vector-icons";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {DarkTheme, DefaultTheme, NavigationContainer} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import * as React from "react";
import {ColorSchemeName} from "react-native";
import {Path, Svg} from "react-native-svg";
import {Box, Unifier} from "react-unifier";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import AboutScreen from "../screens/About";
import HomeScreen from "../screens/HomeScreen";
import LabelsScreen from "../screens/Labels";
import ModalScreen from "../screens/ModalScreen";
import {HomeStackParamList, HomeTabParamList, HomeTabScreenProps} from "../types";
import LinkingConfiguration from "./LinkingConfiguration";

// const AlbumCollection = (
//
// );

// TODO move this into unifier.
const AppLightTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Unifier.theme.lightGray,
  },
};

const AppDarkTheme = {
  ...DarkTheme,
};

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
// const AuthStack = createNativeStackNavigator<AuthStackParamList>();

// function AuthHomeNavigator() {
//   return (
//     <AuthStack.Navigator>
//       <AuthStack.Group screenOptions={{presentation: "modal"}}>
//         <AuthStack.Screen
//           name="Auth"
//           component={AuthScreen}
//           options={{
//             headerShown: false,
//             // title: "Sign in",
//             // When logging out, a pop animation feels intuitive
//             // You can remove this if you want the default 'push' animation
//             // animationTypeForReplace: this.state.isSignout ? "pop" : "push",
//           }}
//         />
//         <AuthStack.Screen name="LogInPage" options={{title: "Sign In"}} component={LogInPage} />
//         <AuthStack.Screen name="SignUpPage" options={{title: "Sign Up"}} component={SignUpPage} />
//       </AuthStack.Group>
//     </AuthStack.Navigator>
//   );
// }

// export function AuthNavigation({colorScheme}: {colorScheme: ColorSchemeName}) {
//   return (
//     <NavigationContainer
//       linking={LinkingConfiguration}
//       theme={colorScheme === "dark" ? AppDarkTheme : AppLightTheme}
//     >
//       <AuthHomeNavigator />
//     </NavigationContainer>
//   );
// }

/**
 * Home Navigation and stack
 */
export const HomeNavigation = ({colorScheme}: {colorScheme: ColorSchemeName}) => {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? AppDarkTheme : AppLightTheme}
    >
      <HomeHomeNavigator />
    </NavigationContainer>
  );
};

const HomeStack = createNativeStackNavigator<HomeStackParamList>();

const HomeHomeNavigator = () => {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        component={HomeBottomTabNavigator}
        name="Search"
        options={{headerShown: false}}
      />
      {/* <HomeStack.Screen name="NotFound" component={NotFoundScreen} options={{title: "Oops!"}} /> */}
      <HomeStack.Group screenOptions={{presentation: "modal"}}>
        <HomeStack.Screen component={ModalScreen} name="Modal" />
      </HomeStack.Group>
    </HomeStack.Navigator>
  );
};

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const HomeBottomTab = createBottomTabNavigator<HomeTabParamList>();

const HomeBottomTabNavigator = () => {
  const colorScheme = useColorScheme();

  return (
    <HomeBottomTab.Navigator
      initialRouteName="HomeHome"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
      }}
    >
      <HomeBottomTab.Screen
        component={HomeScreen}
        name="HomeHome"
        options={({navigation}: HomeTabScreenProps<"HomeHome">) => ({
          title: "Amoeba Search",
          tabBarIcon: ({color}) => <TabBarIcon color={color} name="search" />,
          headerLeft: () => (
            <Box marginLeft={3}>
              <Svg height="30" viewBox="0 0 512 512" width="30">
                <Path d="M496 104a24 24 0 0 0-24-24H40a24 24 0 0 0-24 24v24h480zm-16-80a24 24 0 0 0-24-24H56a24 24 0 0 0-24 24v24h448zM256 325.65c-16.63 0-30 9.93-29.86 22.09s13.5 21.72 29.86 21.72 29.73-9.68 29.87-21.72-13.23-22.09-29.87-22.09zM480 160H32A32 32 0 0 0 .13 194.9l26.19 288A32 32 0 0 0 58.18 512h395.64a32 32 0 0 0 31.86-29.1l26.19-288A32 32 0 0 0 480 160zM256 472.89c-94.26 0-174.39-54.53-179.21-125.15C71.71 273.1 151.82 209.4 256 209.4s184.29 63.7 179.21 138.34c-4.82 70.63-84.95 125.15-179.21 125.15z" />
              </Svg>
            </Box>
          ),
        })}
      />
      <HomeBottomTab.Screen
        component={LabelsScreen}
        name="Labels"
        options={{
          title: "Label Options",
          tabBarIcon: ({color}) => <TabBarIcon color={color} name="tags" />,
        }}
      />
      <HomeBottomTab.Screen
        component={AboutScreen}
        name="About"
        options={{
          title: "About",
          tabBarIcon: ({color}) => <TabBarIcon color={color} name="heart" />,
        }}
      />
    </HomeBottomTab.Navigator>
  );
};

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
const TabBarIcon = (props: {
  name: React.ComponentProps<typeof FontAwesome>["name"];
  color: string;
}) => {
  return <FontAwesome size={30} style={{marginBottom: -3}} {...props} />;
};
