import {StatusBar} from "expo-status-bar";
import React from "react";
import {SafeAreaProvider} from "react-native-safe-area-context";

import {HomeNavigation} from "./navigation";
import {connect, ConnectedProps, Provider} from "react-redux";
import {Box} from "react-unifier";
import {ActivityIndicator} from "react-native";
import * as Notifications from "expo-notifications";
import {combineReducers, configureStore} from "@reduxjs/toolkit";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const store = configureStore({
  reducer: combineReducers({}),
  devTools: true,
});

class AppBodyBare extends React.Component<PropsFromRedux> {
  renderLoading() {
    return (
      <Box
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Box width="100%" flex="grow" justifyContent="center" alignItems="center">
          <ActivityIndicator size="large" />
        </Box>
      </Box>
    );
  }

  render() {
    return (
      <>
        <HomeNavigation colorScheme="light" />
        <StatusBar />
      </>
    );
  }
}

const connector = connect((state: any) => ({}), {});

type PropsFromRedux = ConnectedProps<typeof connector>;
export const AppBody = connector(AppBodyBare);

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <SafeAreaProvider>
          <AppBody />
        </SafeAreaProvider>
      </Provider>
    );
  }
}
