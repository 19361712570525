import * as React from "react";
import {Box, Heading, Page, Text} from "react-unifier";
import {HomeStackScreenProps} from "../types";
import {connect, ConnectedProps} from "react-redux";

interface Props extends PropsFromRedux, HomeStackScreenProps<"Labels"> {}

interface State {
  labelOptions: string[];
}

class AboutScreen extends React.Component<Props, State> {
  render() {
    return (
      <Page scroll={true} navigation={this.props.navigation}>
        <Box
          height="100%"
          width="100%"
          maxWidth={800}
          padding={8}
          // justifyContent="center"
          // alignItems="center"
          // alignSelf="center"
        >
          <Box>
            <Heading>About</Heading>
          </Box>
          <Box paddingY={2}>
            <Text size="lg">Created with ❤️ by Josh Gachnang.</Text>
          </Box>
          <Box paddingY={2}>
            <Text size="lg">
              Album and cover art data provided by Musicbrainz, then run through the Google Vision
              API for labeling and text.
            </Text>
          </Box>
        </Box>
      </Page>
    );
  }
}

const connector = connect(
  (state: any, props) => ({
    ...props,
  }),
  {}
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AboutScreen);
