import axios from "axios";
import * as React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Box, Heading, Page, Text} from "react-unifier";

import {baseUrl} from "../mongoose-redux/constants";
import {HomeStackScreenProps} from "../types";

interface Props extends PropsFromRedux, HomeStackScreenProps<"Labels"> {}

interface State {
  labelOptions: string[];
  numAlbums: number;
  imagesChecked: number;
  imagesDownloaded: number;
  imagesDownloadError: number;
  labelsChecked: number;
  visionCandidates: number;
  downloadCandidates: number;
}

class LabelsScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      labelOptions: [],
      numAlbums: 0,
      imagesChecked: 0,
      imagesDownloaded: 0,
      imagesDownloadError: 0,
      labelsChecked: 0,
      visionCandidates: 0,
      downloadCandidates: 0,
    };
  }

  componentDidMount() {
    this.fetchOptions();
    this.fetchStats();
  }

  fetchOptions = async () => {
    const result = await axios.get(`${baseUrl}/options`);
    console.debug("Label options", result.data?.options);
    this.setState({labelOptions: result.data?.options});
  };

  fetchStats = async () => {
    const result = await axios.get(`${baseUrl}/stats`);
    console.debug("Stats", result.data);
    const {
      numAlbums,
      imagesChecked,
      imagesDownloaded,
      imagesDownloadError,
      labelsChecked,
      visionCandidates,
      downloadCandidates,
    } = result.data ?? {};
    this.setState({
      numAlbums,
      imagesChecked,
      imagesDownloaded,
      imagesDownloadError,
      labelsChecked,
      visionCandidates,
      downloadCandidates,
    });
  };

  render() {
    return (
      <Page navigation={this.props.navigation} scroll>
        <Box
          height="100%"
          maxWidth={800}
          padding={8}
          width="100%"
          // justifyContent="center"
          // alignItems="center"
          // alignSelf="center"
        >
          <Box>
            <Heading>Album Stats</Heading>
          </Box>
          <Box>
            <Text weight="bold">{this.state.numAlbums} albums in the system</Text>
            <Text weight="bold">
              {this.state.imagesChecked} albums with metadata from CoverArtArchive
            </Text>
            <Text weight="bold">{this.state.imagesDownloaded} albums with images downloaded</Text>
            <Text weight="bold">
              {this.state.downloadCandidates} albums with images to download
            </Text>
            <Text weight="bold">
              {this.state.imagesDownloadError} albums with image download errors
            </Text>
            <Text weight="bold">{this.state.labelsChecked} albums processed by Google Vision</Text>
            <Text weight="bold">{this.state.visionCandidates} albums need Google Vision</Text>
          </Box>
          <Box>
            <Heading>Label Options</Heading>
          </Box>
          <Box>
            <Text weight="bold">
              These are the {this.state.labelOptions.length} identified labels by Google Vision
            </Text>
          </Box>
          <Box>
            {this.state.labelOptions.map((o) => (
              <Text key={o}>{o}</Text>
            ))}
          </Box>
        </Box>
      </Page>
    );
  }
}

const connector = connect(
  (state: any, props) => ({
    ...props,
  }),
  {}
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(LabelsScreen);
